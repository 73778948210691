// src/components/Dashboard/GameMaster/SideQuestDetail.js
import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ImageMapper from 'react-img-mapper'; // Updated import
import api from '../../../services/api';
import flagIcon from '../../../assets/flag-icon.png';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
  },
  flagIcon: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    transform: 'translate(-50%, -100%)', // Center horizontally and position above
    pointerEvents: 'none', // Allow clicks to pass through the flag icon
    zIndex: 2, // Ensure it's above the map
  },
}));

const SideQuestDetail = ({ open, handleClose, questId }) => {
  const classes = useStyles();
  const [quest, setQuest] = useState(null);
  const [map, setMap] = useState({
    name: 'sideQuestMap',
    areas: [],
  });
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (open && questId) {
      fetchQuestDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, questId]);

  const fetchQuestDetail = async () => {
    try {
      const response = await api.get(`/quests/side/${questId}`);
      setQuest(response.data);
      const { lokasi_geografis, Peta } = response.data;

      if (lokasi_geografis && Peta) {
        const { lat, lng } = lokasi_geografis;
        setMap({
          name: 'sideQuestMap',
          areas: [
            {
              name: 'quest-location',
              shape: 'circle',
              coords: [lng, lat, 5], // [x, y, radius]
              preFillColor: 'rgba(0, 255, 0, 0.5)',
              fillColor: 'rgba(0, 255, 0, 0.5)',
            },
          ],
        });
      }
    } catch (error) {
      console.error('Error fetching quest detail:', error);
      alert('Error fetching quest details');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Detail Side Quest</DialogTitle>
      <DialogContent>
        {quest ? (
          <>
            <Typography variant="h6">{quest.nama_side_quest}</Typography>
            <Typography variant="body1" gutterBottom>
              Harga: {quest.harga}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Peta Tujuan: {quest.Peta?.nama_peta}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Lokasi Geografis: Lat: {quest.lokasi_geografis.lat}, Lng: {quest.lokasi_geografis.lng}
            </Typography>
            <div className={classes.mapContainer} ref={mapContainerRef}>
              {quest.Peta && quest.lokasi_geografis && (
                <>
                  <Typography variant="subtitle1" gutterBottom>
                    Lokasi Quest pada Peta:
                  </Typography>
                  <ImageMapper
                    src={`https://api.nusalarp.sagaracreativehouse.com/${quest.Peta.gambar_peta}`}
                    map={map}
                    width={600}
                    imgWidth={600}
                    onClick={() => {}} // Disable internal onClick
                  />
                  {/* Flag Icon */}
                  <img
                    src={flagIcon}
                    alt="Flag Icon"
                    className={classes.flagIcon}
                    style={{
                      top: `${100 - quest.lokasi_geografis.lat}%`, // Inverted Y-axis
                      left: `${quest.lokasi_geografis.lng}%`,
                    }}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SideQuestDetail;
