// src/components/Dashboard/GameMaster/UserManagement.js

import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  makeStyles,
  TextField,
  InputLabel,
  FormControl,
  Tabs,
  Tab,
  Typography,
  Snackbar,
  Paper,
} from '@material-ui/core'; // Material-UI v4 core components
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(8),
    padding: theme.spacing(2),
  },
  addButton: {
    margin: theme.spacing(2, 0),
  },
  tabContent: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  formField: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const UserManagement = () => {
  const classes = useStyles();

  // State Variables
  const [users, setUsers] = useState([]);
  const [fraksiList, setFraksiList] = useState([]);
  const [assignOpen, setAssignOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [assignType, setAssignType] = useState('');
  const [questList, setQuestList] = useState([]);
  const [selectedQuest, setSelectedQuest] = useState('');
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    nama: '',
    email: '',
    password: '',
    role: '',
    fraksi_id: '',
    nik: '',
  });
  const [tabIndex, setTabIndex] = useState(0); // 0: Player, 1: Operator, 2: Game Master
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });

  // Fetch Functions
  useEffect(() => {
    fetchUsers();
    fetchFraksi();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      handleSnackbarOpen('Error fetching users');
    }
  };

  const fetchFraksi = async () => {
    try {
      const response = await api.get('/fraksi');
      setFraksiList(response.data);
    } catch (error) {
      console.error('Error fetching fraksi:', error);
      handleSnackbarOpen('Error fetching fraksi');
    }
  };

  // Tab Change Handler
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Assign Dialog Handlers
  const handleAssign = (user) => {
    setSelectedUser(user);
    setAssignOpen(true);
  };

  const handleAssignSubmit = async () => {
    if (!assignType || !selectedQuest) {
      handleSnackbarOpen('Please select both quest type and quest');
      return;
    }

    try {
      await api.post('/users/assign', {
        userId: selectedUser.id,
        questType: assignType,
        questId: selectedQuest,
      });
      setAssignOpen(false);
      fetchUsers();
      handleSnackbarOpen('Operator assigned successfully');
    } catch (error) {
      console.error('Error assigning operator:', error);
      handleSnackbarOpen('Error assigning operator');
    }
  };

  // Add User Dialog Handlers
  const handleAddUser = () => {
    setAddUserOpen(true);
  };

  const handleAddUserSubmit = async () => {
    const { nama, email, password, role, fraksi_id, nik } = newUser;

    // Basic Validation
    if (!nama || !email || !password || !role) {
      handleSnackbarOpen('Please fill in all required fields');
      return;
    }

    if (role === 'Player' && (!fraksi_id || !nik)) {
      handleSnackbarOpen('Please fill in all Player-specific fields');
      return;
    }

    try {
      await api.post('/users', newUser);
      setAddUserOpen(false);
      setNewUser({
        nama: '',
        email: '',
        password: '',
        role: '',
        fraksi_id: '',
        nik: '',
      });
      fetchUsers();
      handleSnackbarOpen('User created successfully');
    } catch (error) {
      console.error('Error creating user:', error);
      handleSnackbarOpen('Error creating user');
    }
  };

  // Delete User Handler (optional)
  const handleDeleteUser = async (user) => {
    if (!window.confirm(`Are you sure you want to delete ${user.nama}?`)) return;

    try {
      await api.delete(`/users/${user.id}`);
      fetchUsers();
      handleSnackbarOpen('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      handleSnackbarOpen('Error deleting user');
    }
  };

  // Snackbar Handlers
  const handleSnackbarOpen = (message) => {
    setSnackbar({
      open: true,
      message,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // Filter Users by Role
  const getUsersByRole = (role) => {
    return users.filter((user) => user.role === role);
  };

  // Render Functions for Each Tab
  const renderPlayerTab = () => {
    const players = getUsersByRole('Player');

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Fraksi</TableCell>
            <TableCell>NIK</TableCell>
            <TableCell>Foto</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {players.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.nama}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.Fraksi?.nama || '---'}</TableCell>
              <TableCell>{user.nik || '---'}</TableCell>
              <TableCell>
                {user.foto && (
                  <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${user.foto}`}
                    alt={user.nama}
                    width="50"
                  />
                )}
              </TableCell>
              <TableCell>
                {/* Optional: Add actions for Players if needed */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteUser(user)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderOperatorTab = () => {
    const operators = getUsersByRole('Operator');

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Fraksi</TableCell>
            <TableCell>NIK</TableCell>
            <TableCell>Foto</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operators.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.nama}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.Fraksi?.nama || '---'}</TableCell>
              <TableCell>{user.nik || '---'}</TableCell>
              <TableCell>
                {user.foto && (
                  <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${user.foto}`}
                    alt={user.nama}
                    width="50"
                  />
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAssign(user)}
                >
                  Assign
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteUser(user)}
                  style={{ marginLeft: 8 }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderGameMasterTab = () => {
    const gameMasters = getUsersByRole('Game Master');

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>NIK</TableCell>
            <TableCell>Foto</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gameMasters.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.nama}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.nik || '---'}</TableCell>
              <TableCell>
                {user.foto && (
                  <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${user.foto}`}
                    alt={user.nama}
                    width="50"
                  />
                )}
              </TableCell>
              <TableCell>
                {/* Optional: Add actions for Game Masters if needed */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteUser(user)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" gutterBottom>
        User Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddUser}
        className={classes.addButton}
      >
        Tambah User
      </Button>
      <Paper>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Player" />
          <Tab label="Operator" />
          <Tab label="Game Master" />
        </Tabs>
        <div className={classes.tabContent}>
          {tabIndex === 0 && renderPlayerTab()}
          {tabIndex === 1 && renderOperatorTab()}
          {tabIndex === 2 && renderGameMasterTab()}
        </div>
      </Paper>

      {/* Dialog Assign */}
      <Dialog open={assignOpen} onClose={() => setAssignOpen(false)}>
        <DialogTitle>Assign Quest</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Pilihan</InputLabel>
            <Select
              value={assignType}
              onChange={async (e) => {
                const selectedType = e.target.value;
                setAssignType(selectedType);
                setSelectedQuest(''); // Reset selected quest
                setQuestList([]); // Reset quest list

                // Fetch quests based on type
                try {
                  const response =
                    selectedType === 'Main Quest'
                      ? await api.get('/quests/main')
                      : await api.get('/quests/side');
                  setQuestList(response.data);
                } catch (error) {
                  console.error('Error fetching quests:', error);
                  handleSnackbarOpen('Error fetching quests');
                }
              }}
            >
              <MenuItem value="Main Quest">Main Quest</MenuItem>
              <MenuItem value="Side Quest">Side Quest</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabel>Quest</InputLabel>
            <Select
              value={selectedQuest}
              onChange={(e) => setSelectedQuest(e.target.value)}
              disabled={!assignType} // Disable until a type is selected
            >
              {questList.map((quest) => (
                <MenuItem key={quest.id} value={quest.id}>
                  {assignType === 'Main Quest'
                    ? quest.nama_main_quest
                    : quest.nama_side_quest}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAssignSubmit} color="primary" disabled={!selectedQuest}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Add User */}
      <Dialog open={addUserOpen} onClose={() => setAddUserOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Tambah User</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            label="Nama"
            fullWidth
            margin="dense"
            value={newUser.nama}
            onChange={(e) => setNewUser({ ...newUser, nama: e.target.value })}
          />
          <TextField
            label="Email"
            fullWidth
            margin="dense"
            type="email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            label="Password"
            fullWidth
            margin="dense"
            type="password"
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select
              value={newUser.role}
              onChange={(e) =>
                setNewUser({ ...newUser, role: e.target.value })
              }
            >
              <MenuItem value="Game Master">Game Master</MenuItem>
              <MenuItem value="Operator">Operator</MenuItem>
              <MenuItem value="Player">Player</MenuItem>
            </Select>
          </FormControl>
          {newUser.role === 'Player' && (
            <>
              <FormControl fullWidth margin="dense">
                <InputLabel>Fraksi</InputLabel>
                <Select
                  value={newUser.fraksi_id}
                  onChange={(e) =>
                    setNewUser({ ...newUser, fraksi_id: e.target.value })
                  }
                >
                  {fraksiList.map((fraksi) => (
                    <MenuItem key={fraksi.id} value={fraksi.id}>
                      {fraksi.nama}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="NIK"
                fullWidth
                margin="dense"
                value={newUser.nik}
                onChange={(e) =>
                  setNewUser({ ...newUser, nik: e.target.value })
                }
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUserOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddUserSubmit} color="primary">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </div>
  );
};

export default UserManagement;
