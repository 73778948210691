// src/components/Dashboard/GameMaster/HiddenQuestManagement.js
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import api from '../../../services/api';
import HiddenQuestDetail from './HiddenQuestDetail'; // Ensure this path is correct

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
    padding: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
}));

const HiddenQuestManagement = () => {
  const classes = useStyles();
  const [hiddenQuests, setHiddenQuests] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingQuest, setEditingQuest] = useState(null);
  const [newHiddenQuest, setNewHiddenQuest] = useState({
    nama_hidden_quest: '',
    fraksi_id: '',
    poin: '',
    description: '',
  });
  const [fraksiList, setFraksiList] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedQuestId, setSelectedQuestId] = useState(null);

  useEffect(() => {
    fetchHiddenQuests();
    fetchFraksi();
  }, []);

  const fetchHiddenQuests = async () => {
    try {
      const response = await api.get('/quests/hidden');
      setHiddenQuests(response.data);
    } catch (error) {
      console.error('Error fetching hidden quests:', error);
      alert('Error fetching hidden quests');
    }
  };

  const fetchFraksi = async () => {
    try {
      const response = await api.get('/fraksi'); // Ensure backend has /fraksi endpoint
      setFraksiList(response.data);
    } catch (error) {
      console.error('Error fetching fraksi:', error);
      alert('Error fetching fraksi');
    }
  };

  const handleAddHiddenQuest = () => {
    setEditingQuest(null);
    setNewHiddenQuest({
      nama_hidden_quest: '',
      fraksi_id: '',
      poin: '',
      description: '',
    });
    setOpen(true);
  };

  const handleEditHiddenQuest = (quest) => {
    setEditingQuest(quest);
    setNewHiddenQuest({
      nama_hidden_quest: quest.nama_hidden_quest,
      fraksi_id: quest.fraksi_id,
      poin: quest.poin,
      description: quest.description || '',
    });
    setOpen(true);
  };

  const handleAddHiddenQuestSubmit = async () => {
    try {
      const { nama_hidden_quest, fraksi_id, poin, description } = newHiddenQuest;

      if ( !fraksi_id || !poin) {
        alert('Fraksi, dan Poin wajib diisi.');
        return;
      }

      const payload = {
        nama_hidden_quest,
        fraksi_id,
        poin: parseInt(poin, 10),
        description,
      };

      await api.post('/quests/hidden', payload);
      setOpen(false);
      setNewHiddenQuest({
        nama_hidden_quest: '',
        fraksi_id: '',
        poin: '',
        description: '',
      });
      fetchHiddenQuests();
      alert('Hidden Quest berhasil dibuat.');
    } catch (error) {
      console.error('Error creating hidden quest:', error);
      alert('Error creating hidden quest');
    }
  };

  const handleUpdateHiddenQuest = async () => {
    try {
      const { nama_hidden_quest, fraksi_id, poin, description } = newHiddenQuest;

      if ( !fraksi_id || !poin) {
        alert('Fraksi, dan Poin wajib diisi.');
        return;
      }

      const payload = {
        nama_hidden_quest,
        fraksi_id,
        poin: parseInt(poin, 10),
        description,
      };

      await api.put(`/quests/hidden/${editingQuest.id}`, payload);
      setOpen(false);
      setEditingQuest(null);
      setNewHiddenQuest({
        nama_hidden_quest: '',
        fraksi_id: '',
        poin: '',
        description: '',
      });
      fetchHiddenQuests();
      alert('Hidden Quest berhasil diperbarui.');
    } catch (error) {
      console.error('Error updating hidden quest:', error);
      alert('Error updating hidden quest');
    }
  };

  const handleDeleteHiddenQuest = async (id) => {
    if (!window.confirm('Apakah Anda yakin ingin menghapus Hidden Quest ini?')) return;

    try {
      await api.delete(`/quests/hidden/${id}`);
      fetchHiddenQuests();
      alert('Hidden Quest berhasil dihapus.');
    } catch (error) {
      console.error('Error deleting hidden quest:', error);
      alert('Error deleting hidden quest');
    }
  };

  const handleViewDetail = (questId) => {
    setSelectedQuestId(questId);
    setDetailOpen(true);
  };

  return (
    <div className={classes.tableContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddHiddenQuest}
        style={{ marginBottom: 16 }}
      >
        Assign Poin
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fraksi</TableCell>
            <TableCell>Poin</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hiddenQuests.map((quest) => (
            <TableRow key={quest.id}>
              <TableCell>{quest.Fraksi?.nama}</TableCell>
              <TableCell>{quest.poin}</TableCell>
              <TableCell>{quest.description || '---'}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditHiddenQuest(quest)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteHiddenQuest(quest.id)}
                  style={{ marginLeft: 8 }}
                >
                  Hapus
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog Add/Edit Hidden Quest */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingQuest ? 'Edit Hidden Quest' : 'Tambah Hidden Quest'}
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel>Fraksi</InputLabel>
            <Select
              value={newHiddenQuest.fraksi_id}
              onChange={(e) =>
                setNewHiddenQuest({ ...newHiddenQuest, fraksi_id: e.target.value })
              }
            >
              {fraksiList.map((fraksi) => (
                <MenuItem key={fraksi.id} value={fraksi.id}>
                  {fraksi.nama}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Poin"
            fullWidth
            margin="dense"
            type="number"
            value={newHiddenQuest.poin}
            onChange={(e) =>
              setNewHiddenQuest({ ...newHiddenQuest, poin: e.target.value })
            }
          />
          <TextField
            label="Deskripsi"
            fullWidth
            margin="dense"
            multiline
            rows={4}
            value={newHiddenQuest.description}
            onChange={(e) =>
              setNewHiddenQuest({ ...newHiddenQuest, description: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          {editingQuest ? (
            <Button onClick={handleUpdateHiddenQuest} color="primary">
              Update
            </Button>
          ) : (
            <Button onClick={handleAddHiddenQuestSubmit} color="primary">
              Tambah
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Hidden Quest Detail Dialog */}
      <HiddenQuestDetail
        open={detailOpen}
        handleClose={() => setDetailOpen(false)}
        questId={selectedQuestId}
      />
    </div>
  );
};

export default HiddenQuestManagement;
