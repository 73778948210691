// src/components/Dashboard/Player/Profil.js
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import api from '../../../services/api';
import { getUser } from '../../../utils/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',  // Center the avatar horizontally
    alignItems: 'center',      // Center the avatar vertically
    flexDirection: 'column',
    textAlign: 'center',
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    marginBottom: theme.spacing(2),
  },
  avatarFraksi: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    marginBottom: theme.spacing(2),
  },
}));

const Profil = () => {
  const classes = useStyles();
  const [profil, setProfil] = useState(null);
  const [poinIndividu, setPoinIndividu] = useState(0); // Store individual points
  const [error, setError] = useState(null); // To handle errors
  const user = getUser(); // Assuming this returns an authenticated user object

  useEffect(() => {
    fetchProfil();
    fetchRankings(); // Fetch rankings after component mounts
  }, []);

  // Fetch user profile information
  const fetchProfil = async () => {
    try {
      const response = await api.get(`/users/${user.id}`);
      setProfil(response.data);
    } catch (error) {
      console.error('Error fetching profil:', error);
      setError('Gagal memuat profil.');
    }
  };

  // Fetch rankings and extract individual points
  const fetchRankings = async () => {
    try {
      const response = await api.get('/quests/rankings');
      const { individualRankings } = response.data;

      // Find the current user's ranking
      const userRanking = individualRankings.find(
        (rank) => rank.player_id === user.id
      );

      if (userRanking) {
        setPoinIndividu(userRanking.total_poin || 0);
      } else {
        setPoinIndividu(0); // Default to 0 if not found
      }
    } catch (error) {
      console.error('Error fetching rankings:', error);
      setError('Gagal memuat poin individu.');
    }
  };

  if (error) {
    return (
      <Container maxWidth="sm">
        <Typography color="error" variant="h6" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!profil) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" align="center">
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper className={classes.container} elevation={3}>
        <div className={classes.avatarContainer}>
          {profil.foto ? (
            <Avatar
              alt={profil.nama}
              src={`https://api.nusalarp.sagaracreativehouse.com/${profil.foto}`}
              className={classes.avatar}
              variant="rounded"
            />
          ) : (
            <Avatar className={classes.avatar} variant="rounded">
              {profil.nama[0]} {/* If no photo, show first letter of name */}
            </Avatar>
          )}

          {profil.Fraksi?.logo ? (
            <Avatar
              alt={profil.Fraksi?.nama}
              src={`https://api.nusalarp.sagaracreativehouse.com/${profil.Fraksi?.logo}`}
              className={classes.avatarFraksi}
              variant="rounded"
            />
          ) : (
            ''
          )}
          <Typography variant="h5">{profil.nama}</Typography>
          <Typography variant="subtitle1">NIK: {profil.nik}</Typography>
          <Typography variant="subtitle1">
            Fraksi: {profil.Fraksi?.nama || 'Belum Ditentukan'}
          </Typography>
          <Typography variant="subtitle1">
            Poin Individu: {poinIndividu}
          </Typography>
        </div>
      </Paper>
    </Container>
  );
};

export default Profil;
