// src/components/Dashboard/Operator/OperatorDashboard.js
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, makeStyles } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MainQuestOperator from './MainQuestOperator';
import SideQuestOperator from './SideQuestOperator';

const useStyles = makeStyles(() => ({
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
}));

const OperatorDashboard = () => {
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleNavigation = (newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        // Main Quest
        window.location.href = `${url}/side-quests`;
        break;
      // case 1:
      //   // Side Quest
      //   window.location.href = `${url}/main-quests`;
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Switch>
        {/* <Route path={`${path}/main-quests`} component={MainQuestOperator} /> */}
        <Route path={`${path}/side-quests`} component={SideQuestOperator} />
        <Route exact path={path}>
          <SideQuestOperator />
        </Route>
      </Switch>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          handleNavigation(newValue);
        }}
        showLabels
        className={classes.bottomNav}
      >
        {/* <BottomNavigationAction label="Main Quests" icon={<AssignmentIcon />} /> */}
        <BottomNavigationAction label="Side Quests" icon={<AssignmentTurnedInIcon />} />
      </BottomNavigation>
    </div>
  );
};

export default OperatorDashboard;
