// src/components/Dashboard/GameMaster/HiddenQuestDetail.js
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  qrCodeImage: {
    width: '100%',
    maxWidth: 200,
    marginTop: theme.spacing(2),
  },
}));

const HiddenQuestDetail = ({ open, handleClose, questId }) => {
  const classes = useStyles();
  const [quest, setQuest] = useState(null);

  useEffect(() => {
    if (questId && open) {
      fetchQuestDetail(questId);
    }
  }, [questId, open]);

  const fetchQuestDetail = async (id) => {
    try {
      const response = await api.get(`/quests/hidden/${id}`);
      setQuest(response.data);
    } catch (error) {
      console.error('Error fetching hidden quest detail:', error);
      alert('Error fetching hidden quest detail');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Detail Hidden Quest</DialogTitle>
      <DialogContent>
        {quest ? (
          <div>
            <Typography variant="h6">Nama Hidden Quest:</Typography>
            <Typography variant="body1" gutterBottom>
              {quest.nama_hidden_quest}
            </Typography>

            <Typography variant="h6">Fraksi:</Typography>
            <Typography variant="body1" gutterBottom>
              {quest.Fraksi?.nama}
            </Typography>

            <Typography variant="h6">Poin:</Typography>
            <Typography variant="body1" gutterBottom>
              {quest.poin}
            </Typography>

            <Typography variant="h6">Deskripsi:</Typography>
            <Typography variant="body1" gutterBottom>
              {quest.description || '---'}
            </Typography>

            <Typography variant="h6">QR Code:</Typography>
            {quest.qr_code ? (
              <img
                src={quest.qr_code}
                alt="QR Code"
                className={classes.qrCodeImage}
              />
            ) : (
              <Typography variant="body2">Tidak ada QR Code.</Typography>
            )}
          </div>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HiddenQuestDetail;
