// src/components/Dashboard/GameMaster/TowerMuseumManagement.js
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import api from '../../../services/api';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
  },
}));

const TowerMuseumManagement = () => {
  const classes = useStyles();
  const [museumTenants, setMuseumTenants] = useState([]);
  const [open, setOpen] = useState(false);
  const [newTenant, setNewTenant] = useState({
    nama_tenant: '',
    deskripsi_tenant: '',
    foto_tenant: null,
    logo_tenant: null,
  });

  useEffect(() => {
    fetchMuseumTenants();
  }, []);

  const fetchMuseumTenants = async () => {
    try {
      const response = await api.get('/market/museum');
      setMuseumTenants(response.data);
    } catch (error) {
      console.error('Error fetching museum tenants:', error);
    }
  };

  const handleAddTenant = () => {
    setOpen(true);
  };

  const handleAddTenantSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('nama_tenant', newTenant.nama_tenant);
      formData.append('deskripsi_tenant', newTenant.deskripsi_tenant);
      if (newTenant.foto_tenant) {
        formData.append('foto_tenant', newTenant.foto_tenant);
      }
      if (newTenant.logo_tenant) {
        formData.append('logo_tenant', newTenant.logo_tenant);
      }

      await api.post('/market/museum', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setOpen(false);
      setNewTenant({
        nama_tenant: '',
        deskripsi_tenant: '',
        foto_tenant: null,
        logo_tenant: null,
      });
      fetchMuseumTenants();
      alert('Tower Museum Tenant created successfully');
    } catch (error) {
      console.error('Error creating museum tenant:', error);
      alert('Error creating museum tenant');
    }
  };

  const handleDeleteTenant = async (id) => {
    try {
      await api.delete(`/market/museum/${id}`);
      fetchMuseumTenants();
      alert('Tower Museum Tenant deleted successfully');
    } catch (error) {
      console.error('Error deleting museum tenant:', error);
      alert('Error deleting museum tenant');
    }
  };

  return (
    <div className={classes.tableContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddTenant}
        style={{ margin: 16 }}
      >
        Tambah Tower Museum Tenant
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama Tenant</TableCell>
            <TableCell>Deskripsi Tenant</TableCell>
            <TableCell>Foto Tenant</TableCell>
            <TableCell>Logo Tenant</TableCell>
            <TableCell>Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {museumTenants.map((tenant) => (
            <TableRow key={tenant.id}>
              <TableCell>{tenant.nama_tenant}</TableCell>
              <TableCell>{tenant.deskripsi_tenant}</TableCell>
              <TableCell>
                {tenant.foto_tenant && (
                  <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${tenant.foto_tenant}`}
                    alt={tenant.nama_tenant}
                    width="100"
                  />
                )}
              </TableCell>
              <TableCell>
                {tenant.logo_tenant && (
                  <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${tenant.logo_tenant}`}
                    alt={`${tenant.nama_tenant} Logo`}
                    width="50"
                  />
                )}
              </TableCell>
              <TableCell>
                <IconButton
                  color="secondary"
                  onClick={() => handleDeleteTenant(tenant.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog Add Tower Museum Tenant */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Tambah Tower Museum Tenant</DialogTitle>
        <DialogContent>
          <TextField
            label="Nama Tenant"
            fullWidth
            margin="dense"
            value={newTenant.nama_tenant}
            onChange={(e) =>
              setNewTenant({ ...newTenant, nama_tenant: e.target.value })
            }
          />
          <TextField
            label="Deskripsi Tenant"
            fullWidth
            margin="dense"
            multiline
            rows={4}
            value={newTenant.deskripsi_tenant}
            onChange={(e) =>
              setNewTenant({ ...newTenant, deskripsi_tenant: e.target.value })
            }
          />
          <div className={classes.inputFile}>
            <label htmlFor="foto_tenant">Foto Tenant:</label>
            <input
              type="file"
              id="foto_tenant"
              name="foto_tenant"
              accept="image/*"
              onChange={(e) =>
                setNewTenant({ ...newTenant, foto_tenant: e.target.files[0] })
              }
            />
          </div>
          <div className={classes.inputFile}>
            <label htmlFor="logo_tenant">Logo Tenant:</label>
            <input
              type="file"
              id="logo_tenant"
              name="logo_tenant"
              accept="image/*"
              onChange={(e) =>
                setNewTenant({ ...newTenant, logo_tenant: e.target.files[0] })
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddTenantSubmit} color="primary">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TowerMuseumManagement;
