// src/components/Dashboard/GameMaster/MainQuestManagement.js
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
  },
}));

const MainQuestManagement = () => {
  const classes = useStyles();
  const [mainQuests, setMainQuests] = useState([]);
  const [open, setOpen] = useState(false);
  const [newMainQuest, setNewMainQuest] = useState({
    nama_main_quest: '',
    phase: '',
    operator_id: '',
    peta_id: '',
  });
  const [operators, setOperators] = useState([]);
  const [petaList, setPetaList] = useState([]);

  useEffect(() => {
    fetchMainQuests();
    fetchOperators();
    fetchPeta();
  }, []);

  const fetchMainQuests = async () => {
    try {
      const response = await api.get('/quests/main');
      setMainQuests(response.data);
    } catch (error) {
      console.error('Error fetching main quests:', error);
    }
  };

  const fetchOperators = async () => {
    try {
      const response = await api.get('/users', {
        params: { role: 'Operator' },
      });
      setOperators(response.data);
    } catch (error) {
      console.error('Error fetching operators:', error);
    }
  };

  const fetchPeta = async () => {
    try {
      const response = await api.get('/peta');
      setPetaList(response.data);
    } catch (error) {
      console.error('Error fetching peta:', error);
    }
  };

  const handleAddMainQuest = () => {
    setOpen(true);
  };

  const handleAddMainQuestSubmit = async () => {
    try {
      await api.post('/quests/main', newMainQuest);
      setOpen(false);
      setNewMainQuest({
        nama_main_quest: '',
        phase: '',
        operator_id: '',
        peta_id: '',
      });
      fetchMainQuests();
      alert('Main Quest created successfully');
    } catch (error) {
      console.error('Error creating main quest:', error);
      alert('Error creating main quest');
    }
  };

  const handleDeleteMainQuest = async (id) => {
    try {
      await api.delete(`/quests/main/${id}`);
      fetchMainQuests();
      alert('Main Quest deleted successfully');
    } catch (error) {
      console.error('Error deleting main quest:', error);
      alert('Error deleting main quest');
    }
  };

  return (
    <div className={classes.tableContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddMainQuest}
        style={{ margin: 16 }}
      >
        Tambah Main Quest
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama Main Quest</TableCell>
            <TableCell>Phase</TableCell>
            <TableCell>Operator</TableCell>
            <TableCell>Peta Tujuan</TableCell>
            <TableCell>QR Code</TableCell>
            <TableCell>Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mainQuests.map((quest) => (
            <TableRow key={quest.id}>
              <TableCell>{quest.nama_main_quest}</TableCell>
              <TableCell>{quest.phase}</TableCell>
              <TableCell>{quest.User?.nama || 'Belum Ditugaskan'}</TableCell>
              <TableCell>{quest.Peta?.nama_peta}</TableCell>
              <TableCell>
                {quest.qr_code && (
                  <img src={quest.qr_code} alt="QR Code" width="100" />
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteMainQuest(quest.id)}
                >
                  Hapus
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog Add Main Quest */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Tambah Main Quest</DialogTitle>
        <DialogContent>
          <TextField
            label="Nama Main Quest"
            fullWidth
            margin="dense"
            value={newMainQuest.nama_main_quest}
            onChange={(e) =>
              setNewMainQuest({ ...newMainQuest, nama_main_quest: e.target.value })
            }
          />
          <TextField
            label="Phase"
            fullWidth
            margin="dense"
            type="number"
            value={newMainQuest.phase}
            onChange={(e) =>
              setNewMainQuest({ ...newMainQuest, phase: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Operator</InputLabel>
            <Select
              value={newMainQuest.operator_id}
              onChange={(e) =>
                setNewMainQuest({ ...newMainQuest, operator_id: e.target.value })
              }
            >
              {operators.map((operator) => (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.nama}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Peta Tujuan</InputLabel>
            <Select
              value={newMainQuest.peta_id}
              onChange={(e) =>
                setNewMainQuest({ ...newMainQuest, peta_id: e.target.value })
              }
            >
              {petaList.map((peta) => (
                <MenuItem key={peta.id} value={peta.id}>
                  {peta.nama_peta}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddMainQuestSubmit} color="primary">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MainQuestManagement;
