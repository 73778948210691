// src/components/Dashboard/Operator/RankingsDialog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  makeStyles,
} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const RankingsDialog = ({ open, handleClose, quest }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [playingPlayers, setPlayingPlayers] = useState([]);
  const [finishedPlayers, setFinishedPlayers] = useState([]);

  useEffect(() => {
    if (open) {
      fetchPlayingPlayers();
      fetchFinishedPlayers();
    }
    // eslint-disable-next-line
  }, [open]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchPlayingPlayers = async () => {
    try {
      const response = await api.get(`/quests/rankings/${quest.id}`, {
        params: { status: 'bermain', tipe_quest: 'Main' },
      });
      setPlayingPlayers(response.data);
    } catch (error) {
      console.error('Error fetching playing players:', error);
    }
  };

  const fetchFinishedPlayers = async () => {
    try {
      const response = await api.get(`/quests/rankings/${quest.id}`, {
        params: { status: 'selesai', tipe_quest: 'Main' },
      });
      setFinishedPlayers(response.data);
    } catch (error) {
      console.error('Error fetching finished players:', error);
    }
  };

  const handleGivePoints = async (playerId) => {
    try {
      const poin = prompt('Masukkan jumlah poin yang ingin diberikan:');
      if (poin === null) return; // User canceled
      const poinNumber = parseInt(poin);
      if (isNaN(poinNumber) || poinNumber <= 0) {
        alert('Jumlah poin harus berupa angka positif.');
        return;
      }

      await api.post(`/quests/main/${quest.id}/give-poin`, {
        player_id: playerId,
        poin: poinNumber,
      });

      alert('Poin berhasil diberikan.');
      // Refresh data
      fetchPlayingPlayers();
      fetchFinishedPlayers();
    } catch (error) {
      console.error('Error giving points:', error);
      alert('Gagal memberikan poin.');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Rankings - {quest.nama_main_quest}</DialogTitle>
      <DialogContent>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Sedang Bermain" />
          <Tab label="Selesai" />
        </Tabs>

        {tabValue === 0 && (
          <Box className={classes.tabPanel}>
            <Typography variant="h6" gutterBottom>
              Sedang Bermain
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nama Pemain</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playingPlayers.length > 0 ? (
                  playingPlayers.map((player) => (
                    <TableRow key={player.id}>
                      <TableCell>{player.nama}</TableCell>
                      <TableCell>{player.status}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => handleGivePoints(player.id)}
                        >
                          Beri Poin
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      Tidak ada pemain sedang bermain.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        )}

        {tabValue === 1 && (
          <Box className={classes.tabPanel}>
            <Typography variant="h6" gutterBottom>
              Selesai
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nama Pemain</TableCell>
                  <TableCell>Poin</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finishedPlayers.length > 0 ? (
                  finishedPlayers
                    .sort((a, b) => b.poin - a.poin)
                    .map((player) => (
                      <TableRow key={player.id}>
                        <TableCell>{player.nama}</TableCell>
                        <TableCell>{player.poin}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      Tidak ada pemain yang menyelesaikan quest.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RankingsDialog;
