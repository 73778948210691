// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  makeStyles,
  Paper,
} from '@material-ui/core';
import api from '../services/api';
import { setToken, setUser } from '../utils/auth';
import { useHistory } from 'react-router-dom';

// Import any necessary images
// Ensure these images are placed in the public folder or imported appropriately
// Example: import organizer1 from '../assets/organizer1.png';

const useStyles = makeStyles((theme) => ({
  // Existing styles...
  dashboardContainer: {
    minHeight: '100vh',
    backgroundImage: 'url(/bg.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
  },
  // Splash screen styles
  splashScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'url(/bg.png)',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 9999, // Ensure it's on top
    opacity: 1,
    transition: 'opacity 1s ease-out',
  },
  splashFadeOut: {
    opacity: 0,
  },
  topOrganizers: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    '& > img': {
      margin: theme.spacing(1),
      width: '100px', // Adjust size as needed
      height: 'auto',
    },
  },
  mainLogo: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: '400px', // Adjust size as needed
      height: 'auto',
    },
  },
  bottomPartners: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    paddingBottom: theme.spacing(4),
    '& > div': {
      textAlign: 'center',
      '& > img': {
        margin: theme.spacing(1),
        width: '30px', // Adjust size as needed
        height: 'auto',
      },
      '& > h4': {
        marginTop: theme.spacing(2),
        fontSize: '1rem',
      },
    },
  },
  // Login form styles
  background: {
    // Existing styles...
    backgroundImage: 'url(/bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    },
  },
  container: {
    position: 'relative',
    zIndex: 2,
    padding: theme.spacing(4),
    maxWidth: '400px',
    width: '100%',
  },
  root: {
    padding: theme.spacing(4),
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: theme.spacing(1),
  },
  title: {
    fontFamily: 'MedievalSharp, Arial',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    color: '#333',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const [showSplash, setShowSplash] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [email, setEmail] = useState(''); // Optionally set default for testing
  const [password, setPassword] = useState(''); // Optionally set default for testing
  const history = useHistory();

  useEffect(() => {
    // Start the timer for splash screen
    const timer = setTimeout(() => {
      setFadeOut(true);
      // After the fade-out transition, hide the splash screen
      const fadeTimer = setTimeout(() => {
        setShowSplash(false);
      }, 1000); // Duration should match the CSS transition duration

      return () => clearTimeout(fadeTimer);
    }, 4000); // Display splash screen for 4 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleLogin = async () => {
    try {
      const response = await api.post('/auth/login', { email, password });
      setToken(response.data.token);
      setUser(response.data.user);
      history.push('/dashboard');
    } catch (error) {
      alert('Login gagal: ' + (error.response?.data?.error || error.message));
    }
  };

  return (
    <>
      {showSplash && (
        <div
          className={`${classes.splashScreen} ${
            fadeOut ? classes.splashFadeOut : ''
          }`}
        >
          {/* Top Organizers */}
          <div className={classes.topOrganizers}>
            <img src="./logo/sagara_logo.png" alt="Organizer 1" />
            <img src="./logo/san_angelo.png" alt="Organizer 2" />
            <img src="./logo/monogatari_logo.png" alt="Organizer 3" />
          </div>

          {/* Main Event Logo */}
          <div className={classes.mainLogo}>
            <img src="./logo/tower_logo.png" alt="Event Logo" />
          </div>

          {/* Bottom Partners */}
          <div className={classes.bottomPartners}>
            {/* Media Partners */}
            <div>
              <Typography variant="h6">Media Partners</Typography>
              <img src="./logo/medpar/07 Logo Media Formasi - Vertical 3.png" alt="Media Partner 1" />
              <img src="./logo/medpar/Baraya Weebu.png" alt="Media Partner 2" />
              <img src="./logo/medpar/Black.png" alt="Media Partner 3" />
              <img src="./logo/medpar/East Coast Logo.jpg" alt="Media Partner 3" />
              <img src="./logo/medpar/Geekbot_Portal_Logo-removebg-preview black.png" alt="Media Partner 3" />
              <img src="./logo/medpar/J-Fest Hunter.png" alt="Media Partner 3" />
              <img src="./logo/medpar/LOGO COSER BANDUNG TRANSPARANT.png" alt="Media Partner 3" />
              <img src="./logo/medpar/Logo Saldaysia.JPG" alt="Media Partner 3" />
              <img src="./logo/medpar/Wibu Baleendah.png" alt="Media Partner 3" />
            </div>

            {/* Sponsors */}
            <div>
              <Typography variant="h6">Sponsors</Typography>
              <img src="./logo/sponsor/Fairy Garden Logo.png" alt="Sponsor 1" />
              <img src="./logo/sponsor/The Lodge Maribaya Logo.png" alt="Sponsor 2" />
            </div>

            {/* Community Partners */}
            <div>
              <Typography variant="h6">Community Partners</Typography>
              <img src="./logo/cp/Ascendant of the Light.png" alt="Community Partner 1" />
              <img src="./logo/cp/Crust de Justinia (bendera JUSTINA).png" alt="Community Partner 2" />
              <img src="./logo/cp/Gwaith logo (Hema).png" alt="Community Partner 3" />
              <img src="./logo/cp/Isengard.png" alt="Community Partner 1" />
              <img src="./logo/cp/Kajene Guild final.png" alt="Community Partner 2" />
              <img src="./logo/cp/Larp Bekasi.jpg" alt="Community Partner 3" />
              <img src="./logo/cp/LOGO JUSTINA UHUY.png" alt="Community Partner 1" />
              <img src="./logo/cp/Logo Ravens.jpg" alt="Community Partner 2" />
              <img src="./logo/cp/LYCAN Cosplay Team.png" alt="Community Partner 3" />
              <img src="./logo/cp/Sribuza.png" alt="Community Partner 1" />
              <img src="./logo/cp/STDI Menempa.png" alt="Community Partner 2" />
              <img src="./logo/cp/Torus.png" alt="Community Partner 3" />
            </div>
          </div>
        </div>
      )}

      {!showSplash && (
        <div className={classes.dashboardContainer}>
          <Container maxWidth="sm">
            <Paper className={classes.root} elevation={3}>
              <Typography variant="h4" className={classes.title}>
                NusaLARP Login
              </Typography>
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleLogin}
                className={classes.button}
              >
                Masuk
              </Button>
            </Paper>
          </Container>
        </div>
      )}
    </>
  );
};

export default Login;
