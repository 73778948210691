// src/components/Dashboard/GameMaster/PetaManagement.js
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
  },
}));

const PetaManagement = () => {
  const classes = useStyles();
  const [petaList, setPetaList] = useState([]);
  const [open, setOpen] = useState(false);
  const [newPeta, setNewPeta] = useState({
    nama_peta: '',
    nama_event: '',
    gambar_peta: null,
  });

  useEffect(() => {
    fetchPeta();
  }, []);

  const fetchPeta = async () => {
    try {
      const response = await api.get('/peta');
      setPetaList(response.data);
    } catch (error) {
      console.error('Error fetching peta:', error);
    }
  };

  const handleAddPeta = () => {
    setOpen(true);
  };

  const handleAddPetaSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('nama_peta', newPeta.nama_peta);
      formData.append('nama_event', newPeta.nama_event);
      formData.append('gambar_peta', newPeta.gambar_peta);

      await api.post('/peta', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setOpen(false);
      fetchPeta();
      alert('Peta created successfully');
    } catch (error) {
      console.error('Error creating peta:', error);
      alert('Error creating peta');
    }
  };

  return (
    <div className={classes.tableContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddPeta}
        style={{ margin: 16 }}
      >
        Tambah Peta
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama Peta</TableCell>
            <TableCell>Nama Event</TableCell>
            <TableCell>Gambar Peta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {petaList.map((peta) => (
            <TableRow key={peta.id}>
              <TableCell>{peta.nama_peta}</TableCell>
              <TableCell>{peta.nama_event}</TableCell>
              <TableCell>
                <img
                  src={`https://api.nusalarp.sagaracreativehouse.com/${peta.gambar_peta}`}
                  alt={peta.nama_peta}
                  width="100"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog Add Peta */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Tambah Peta</DialogTitle>
        <DialogContent>
          <TextField
            label="Nama Peta"
            fullWidth
            margin="dense"
            value={newPeta.nama_peta}
            onChange={(e) =>
              setNewPeta({ ...newPeta, nama_peta: e.target.value })
            }
          />
          <TextField
            label="Nama Event"
            fullWidth
            margin="dense"
            value={newPeta.nama_event}
            onChange={(e) =>
              setNewPeta({ ...newPeta, nama_event: e.target.value })
            }
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) =>
              setNewPeta({ ...newPeta, gambar_peta: e.target.files[0] })
            }
            style={{ marginTop: 16 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddPetaSubmit} color="primary">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PetaManagement;
