// src/theme.js
import { createMuiTheme } from '@material-ui/core/styles';
import MedievalSharp from './assets/fonts/MedievalSharp-Regular.ttf';

const medievalSharp = {
  fontFamily: 'MedievalSharp',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('MedievalSharp'),
    url(${MedievalSharp}) format('truetype')
  `,
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6D4C41', // Warna coklat untuk tema medieval
    },
    secondary: {
      main: '#D7CCC8', // Warna krem
    },
  },
  typography: {
    fontFamily: 'MedievalSharp, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [medievalSharp],
      },
    },
  },
});

export default theme;
