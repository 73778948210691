import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Container,
  makeStyles,
  Paper,
} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}));

const MainQuestList = () => {
  const classes = useStyles();
  const [mainQuests, setMainQuests] = useState([]);
  const [mapImage, setMapImage] = useState('');
  const [openMapDialog, setOpenMapDialog] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetchMainQuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMainQuests = async () => {
    try {
      // Dapatkan data main quests berdasarkan peta_id user
      const response = await api.get(`/quests/main/peta/${user.map_id}`);
      setMainQuests(response.data);

      // Dapatkan gambar peta
      if (response.data.length > 0) {
        const petaId = response.data[0].peta_id;
        const petaResponse = await api.get(`/peta/${petaId}`);
        setMapImage(petaResponse.data.gambar_peta);
      }
    } catch (error) {
      console.error('Fetch Main Quests Error:', error);
    }
  };

  const handleOpenMapDialog = () => {
    setOpenMapDialog(true);
  };

  const handleCloseMapDialog = () => {
    setOpenMapDialog(false);
  };

  return (
    <Container maxWidth="md">
      <Paper className={classes.container} elevation={3}>
        <Box sx={{ p: 2 }}>
        {mapImage && (
            <Box sx={{ mb: 2 }}>
            <Button variant="contained" color="primary" onClick={handleOpenMapDialog}>
                Lihat Maps
            </Button>
            <Dialog
                open={openMapDialog}
                onClose={handleCloseMapDialog}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Peta</DialogTitle>
                <DialogContent>
                <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${mapImage}`}
                    alt="Map"
                    style={{ width: '100%', height: 'auto' }}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseMapDialog} color="primary">
                    Tutup
                </Button>
                </DialogActions>
            </Dialog>
            </Box>
        )}
        <Typography variant="h5" gutterBottom>
            Main Quests
        </Typography>
        <List>
            {mainQuests.map((quest) => (
            <React.Fragment key={quest.id}>
                <ListItem button>
                <ListItemText
                    primary={quest.nama_main_quest}
                    secondary={`Phase: ${quest.phase}`}
                />
                </ListItem>
                <Divider />
            </React.Fragment>
            ))}
        </List>
        </Box>
      </Paper>
    </Container>
  );
};

export default MainQuestList;
