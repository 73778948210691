// src/components/Common/Footer.js
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: 56, // Adjust based on BottomNavigation height
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} NusaLARP. All rights reserved.
      </Typography>
    </div>
  );
};

export default Footer;
