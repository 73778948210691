// src/components/Dashboard/Player/Rankings.js
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Paper,
} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}));

const Rankings = () => {
  const classes = useStyles();
  const [fraksiRankings, setFraksiRankings] = useState([]);
  const [individualRankings, setIndividualRankings] = useState([]);

  useEffect(() => {
    fetchRankings();
  }, []);

  const fetchRankings = async () => {
    try {
      const response = await api.get('/quests/rankings');
      setFraksiRankings(response.data.fraksiRankings);
      setIndividualRankings(response.data.individualRankings);
    } catch (error) {
      console.error('Error fetching rankings:', error);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper className={classes.container} elevation={3}>
        <Typography variant="h5" gutterBottom>
          Ranking Poin Fraksi
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Fraksi</TableCell>
              <TableCell>Total Poin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fraksiRankings.map((rank) => (
              <TableRow key={rank.fraksi_id}>
                <TableCell>{rank.Fraksi?.nama}</TableCell>
                <TableCell>{rank.total_poin}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Typography variant="h5" gutterBottom style={{ marginTop: 32 }}>
          Ranking Poin Individu
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nama Player</TableCell>
              <TableCell>Total Poin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {individualRankings.map((rank) => (
              <TableRow key={rank.player_id}>
                <TableCell>{rank.Player?.nama}</TableCell>
                <TableCell>{rank.total_poin}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default Rankings;
