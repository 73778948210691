// src/pages/Dashboard.js
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { getUser } from '../utils/auth';
import GameMasterDashboard from '../components/Dashboard/GameMaster/GameMasterDashboard';
import OperatorDashboard from '../components/Dashboard/Operator/OperatorDashboard';
import PlayerDashboard from '../components/Dashboard/Player/PlayerDashboard';
import NotFound from './NotFound';
import Layout from '../components/Layout/Layout';

const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    minHeight: '100vh', // Ensure it covers the full viewport height
    backgroundImage: 'url(/bg.png)', // Reference to public/bg.png
    backgroundSize: 'cover', // Make sure the image covers the entire area
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    backgroundAttachment: 'fixed', // Make the background fixed during scroll
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const user = getUser();

  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.dashboardContainer}>
      <Layout>
        <Switch>
          {user.role === 'Game Master' && (
            <Route path="/dashboard" component={GameMasterDashboard} />
          )}
          {user.role === 'Operator' && (
            <Route path="/dashboard" component={OperatorDashboard} />
          )}
          {user.role === 'Player' && (
            <Route path="/dashboard" component={PlayerDashboard} />
          )}
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </div>
  );
};

export default Dashboard;
