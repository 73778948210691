// src/components/Dashboard/GameMaster/CraftMarketManagement.js
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import api from '../../../services/api';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  inputFile: {
    marginTop: theme.spacing(2),
  },
}));

const CraftMarketManagement = () => {
  const classes = useStyles();
  const [craftTenants, setCraftTenants] = useState([]);
  const [open, setOpen] = useState(false);
  const [newTenant, setNewTenant] = useState({
    nama_tenant: '',
    deskripsi_tenant: '',
    foto_tenant: null,
    logo_tenant: null,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchCraftTenants();
  }, []);

  const fetchCraftTenants = async () => {
    try {
      const response = await api.get('/market/craft');
      setCraftTenants(response.data);
    } catch (error) {
      console.error('Error fetching craft tenants:', error);
      setError('Gagal mengambil data Craft Tenants');
    }
  };

  const handleAddTenant = () => {
    setOpen(true);
    setError('');
    setSuccess('');
  };

  const handleAddTenantSubmit = async () => {
    try {
      const { nama_tenant, deskripsi_tenant, foto_tenant, logo_tenant } = newTenant;

      if (!nama_tenant || !deskripsi_tenant) {
        setError('Nama dan Deskripsi Tenant wajib diisi');
        return;
      }

      const formData = new FormData();
      formData.append('nama_tenant', nama_tenant);
      formData.append('deskripsi_tenant', deskripsi_tenant);
      if (foto_tenant) {
        formData.append('foto_tenant', foto_tenant);
      }
      if (logo_tenant) {
        formData.append('logo_tenant', logo_tenant);
      }

      const response = await api.post('/market/craft', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setOpen(false);
        setNewTenant({
          nama_tenant: '',
          deskripsi_tenant: '',
          foto_tenant: null,
          logo_tenant: null,
        });
        fetchCraftTenants();
        setSuccess('Craft Tenant berhasil ditambahkan');
      } else {
        setError('Gagal menambahkan Craft Tenant');
      }
    } catch (error) {
      console.error('Error creating craft tenant:', error);
      setError('Gagal menambahkan Craft Tenant');
    }
  };

  const handleDeleteTenant = async (id) => {
    if (!window.confirm('Apakah Anda yakin ingin menghapus Craft Tenant ini?')) return;

    try {
      const response = await api.delete(`/market/craft/${id}`);
      if (response.status === 200) {
        fetchCraftTenants();
        alert('Craft Tenant berhasil dihapus');
      } else {
        alert('Gagal menghapus Craft Tenant');
      }
    } catch (error) {
      console.error('Error deleting craft tenant:', error);
      alert('Gagal menghapus Craft Tenant');
    }
  };

  return (
    <div className={classes.tableContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddTenant}
        style={{ margin: 16 }}
      >
        Tambah Craft Tenant
      </Button>
      {error && (
        <div style={{ color: 'red', marginLeft: 16 }}>
          {error}
        </div>
      )}
      {success && (
        <div style={{ color: 'green', marginLeft: 16 }}>
          {success}
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama Tenant</TableCell>
            <TableCell>Deskripsi Tenant</TableCell>
            <TableCell>Foto Tenant</TableCell>
            <TableCell>Logo Tenant</TableCell>
            <TableCell>Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {craftTenants.map((tenant) => (
            <TableRow key={tenant.id}>
              <TableCell>{tenant.nama_tenant}</TableCell>
              <TableCell>{tenant.deskripsi_tenant}</TableCell>
              <TableCell>
                {tenant.foto_tenant ? (
                  <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${tenant.foto_tenant}`}
                    alt={tenant.nama_tenant}
                    width="100"
                    className={classes.image}
                  />
                ) : (
                  'Tidak Ada'
                )}
              </TableCell>
              <TableCell>
                {tenant.logo_tenant ? (
                  <img
                    src={`https://api.nusalarp.sagaracreativehouse.com/${tenant.logo_tenant}`}
                    alt={`${tenant.nama_tenant} Logo`}
                    width="50"
                    className={classes.image}
                  />
                ) : (
                  'Tidak Ada'
                )}
              </TableCell>
              <TableCell>
                <IconButton
                  color="secondary"
                  onClick={() => handleDeleteTenant(tenant.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog Add Craft Tenant */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Tambah Craft Tenant</DialogTitle>
        <DialogContent>
          <TextField
            label="Nama Tenant"
            fullWidth
            margin="dense"
            value={newTenant.nama_tenant}
            onChange={(e) =>
              setNewTenant({ ...newTenant, nama_tenant: e.target.value })
            }
            required
          />
          <TextField
            label="Deskripsi Tenant"
            fullWidth
            margin="dense"
            multiline
            rows={4}
            value={newTenant.deskripsi_tenant}
            onChange={(e) =>
              setNewTenant({ ...newTenant, deskripsi_tenant: e.target.value })
            }
            required
          />
          <div className={classes.inputFile}>
            <label htmlFor="foto_tenant">Foto Tenant:</label>
            <input
              type="file"
              id="foto_tenant"
              name="foto_tenant"
              accept="image/*"
              onChange={(e) =>
                setNewTenant({ ...newTenant, foto_tenant: e.target.files[0] })
              }
            />
          </div>
          <div className={classes.inputFile}>
            <label htmlFor="logo_tenant">Logo Tenant:</label>
            <input
              type="file"
              id="logo_tenant"
              name="logo_tenant"
              accept="image/*"
              onChange={(e) =>
                setNewTenant({ ...newTenant, logo_tenant: e.target.files[0] })
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddTenantSubmit} color="primary">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CraftMarketManagement;
