// src/components/Dashboard/GameMaster/SideQuestManagement.js
import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import api from '../../../services/api';
import ImageMapper from 'react-img-mapper'; // Updated import
import SideQuestDetail from './SideQuestDetail'; // Ensure this path is correct

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
  },
  mapContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
    cursor: 'crosshair', // Indicate that the map is clickable
  },
  flagIcon: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    transform: 'translate(-50%, -100%)', // Adjust to position the flag correctly
    pointerEvents: 'none', // Allow clicks to pass through the flag icon
  },
}));

const SideQuestManagement = () => {
  const classes = useStyles();
  const [sideQuests, setSideQuests] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingQuest, setEditingQuest] = useState(null);
  const [newSideQuest, setNewSideQuest] = useState({
    nama_side_quest: '',
    harga: '',
    peta_id: '',
    lokasi_geografis: '',
  });
  const [petaList, setPetaList] = useState([]);
  const [selectedPeta, setSelectedPeta] = useState(null);
  const [map, setMap] = useState({
    name: 'sideQuestMap',
    areas: [],
  });
  const [currentLocation, setCurrentLocation] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedQuestId, setSelectedQuestId] = useState(null);

  const mapContainerRef = useRef(null);

  useEffect(() => {
    fetchSideQuests();
    fetchPeta();
  }, []);

  const fetchSideQuests = async () => {
    try {
      const response = await api.get('/quests/side');
      setSideQuests(response.data);
    } catch (error) {
      console.error('Error fetching side quests:', error);
    }
  };

  const fetchPeta = async () => {
    try {
      const response = await api.get('/peta');
      setPetaList(response.data);
    } catch (error) {
      console.error('Error fetching peta:', error);
    }
  };

  const handlePetaChange = async (e) => {
    const petaId = e.target.value;
    setNewSideQuest({ ...newSideQuest, peta_id: petaId, lokasi_geografis: '' });
    const selected = petaList.find((peta) => peta.id === petaId);
    setSelectedPeta(selected);
    // Initialize map areas
    setMap({
      name: 'sideQuestMap',
      areas: [],
    });
    setCurrentLocation(null);
  };

  const handleMapClick = (e) => {
    if (!mapContainerRef.current) return;

    const bounds = mapContainerRef.current.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const y = e.clientY - bounds.top;
    const width = bounds.width;
    const height = bounds.height;

    // Ensure click is within the image boundaries
    if (x < 0 || y < 0 || x > width || y > height) {
      return;
    }

    // Convert pixel to percentage
    const percentX = ((x / width) * 100).toFixed(4); // string
    const percentY = ((y / height) * 100).toFixed(4); // string

    console.log(`Clicked at X: ${x}, Y: ${y}`);
    console.log(`Converted to Percent X: ${percentX}%, Percent Y: ${percentY}%`);

    // Invert Y-axis for accurate mapping
    const invertedPercentY = (100 - percentY).toFixed(4); // string

    // Convert to float
    const lat = parseFloat(invertedPercentY);
    const lng = parseFloat(percentX);

    setCurrentLocation({ lat, lng });

    // Update map areas to show marker
    const newArea = {
      name: 'quest-location',
      shape: 'circle',
      coords: [lng, lat, 5], // [x, y, radius]
      preFillColor: 'rgba(255, 0, 0, 0.5)',
      fillColor: 'rgba(255, 0, 0, 0.5)',
    };

    setMap({
      ...map,
      areas: [newArea],
    });
  };

  const handleAddSideQuest = () => {
    setEditingQuest(null);
    setNewSideQuest({
      nama_side_quest: '',
      harga: '',
      peta_id: '',
      lokasi_geografis: '',
    });
    setMap({
      name: 'sideQuestMap',
      areas: [],
    });
    setCurrentLocation(null);
    setOpen(true);
  };

  const handleEditSideQuest = (quest) => {
    setEditingQuest(quest);
    setNewSideQuest({
      nama_side_quest: quest.nama_side_quest,
      harga: quest.harga,
      peta_id: quest.peta_id,
      lokasi_geografis: quest.lokasi_geografis,
    });
    const selected = petaList.find((peta) => peta.id === quest.peta_id);
    setSelectedPeta(selected);
    setMap({
      name: 'sideQuestMap',
      areas: [
        {
          name: 'quest-location',
          shape: 'circle',
          coords: [
            quest.lokasi_geografis.lng,
            quest.lokasi_geografis.lat,
            5,
          ],
          preFillColor: 'rgba(255, 0, 0, 0.5)',
          fillColor: 'rgba(255, 0, 0, 0.5)',
        },
      ],
    });
    setCurrentLocation(quest.lokasi_geografis);
    setOpen(true);
  };

  const handleAddSideQuestSubmit = async () => {
    try {
      if (!newSideQuest.peta_id || !currentLocation) {
        alert('Silakan pilih peta dan lokasi geografis.');
        return;
      }

      const payload = {
        ...newSideQuest,
        lokasi_geografis: currentLocation, // { lat: number, lng: number }
      };

      await api.post('/quests/side', payload);
      setOpen(false);
      setNewSideQuest({
        nama_side_quest: '',
        harga: '',
        peta_id: '',
        lokasi_geografis: '',
      });
      setMap({
        name: 'sideQuestMap',
        areas: [],
      });
      setCurrentLocation(null);
      fetchSideQuests();
      alert('Side Quest created successfully');
    } catch (error) {
      console.error('Error creating side quest:', error);
      alert('Error creating side quest');
    }
  };

  const handleUpdateSideQuest = async () => {
    try {
      if (!newSideQuest.peta_id || !currentLocation) {
        alert('Silakan pilih peta dan lokasi geografis.');
        return;
      }

      const payload = {
        ...newSideQuest,
        lokasi_geografis: currentLocation, // { lat: number, lng: number }
      };

      await api.put(`/quests/side/${editingQuest.id}`, payload);
      setOpen(false);
      setEditingQuest(null);
      setNewSideQuest({
        nama_side_quest: '',
        harga: '',
        peta_id: '',
        lokasi_geografis: '',
      });
      setMap({
        name: 'sideQuestMap',
        areas: [],
      });
      setCurrentLocation(null);
      fetchSideQuests();
      alert('Side Quest updated successfully');
    } catch (error) {
      console.error('Error updating side quest:', error);
      alert('Error updating side quest');
    }
  };

  const handleDeleteSideQuest = async (id) => {
    try {
      await api.delete(`/quests/side/${id}`);
      fetchSideQuests();
      alert('Side Quest deleted successfully');
    } catch (error) {
      console.error('Error deleting side quest:', error);
      alert('Error deleting side quest');
    }
  };

  const handleViewDetail = (questId) => {
    setSelectedQuestId(questId);
    setDetailOpen(true);
  };

  return (
    <div className={classes.tableContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddSideQuest}
        style={{ margin: 16 }}
      >
        Tambah Side Quest
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama Side Quest</TableCell>
            <TableCell>Point (Max)</TableCell>
            <TableCell>Peta Tujuan</TableCell>
            <TableCell>Lokasi Geografis</TableCell>
            <TableCell>QR Code</TableCell>
            <TableCell>Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sideQuests.map((quest) => (
            <TableRow key={quest.id}>
              <TableCell>{quest.nama_side_quest}</TableCell>
              <TableCell>{quest.harga}</TableCell>
              <TableCell>{quest.Peta?.nama_peta}</TableCell>
              <TableCell>
                {quest.lokasi_geografis
                  ? `Lat: ${quest.lokasi_geografis.lat}, Lng: ${quest.lokasi_geografis.lng}`
                  : 'Belum Ditentukan'}
              </TableCell>
              <TableCell>
                {quest.qr_code && (
                  <img src={quest.qr_code} alt="QR Code" width="100" />
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditSideQuest(quest)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteSideQuest(quest.id)}
                  style={{ marginLeft: 8 }}
                >
                  Hapus
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleViewDetail(quest.id)}
                  style={{ marginLeft: 8 }}
                >
                  Detail
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog Add/Edit Side Quest */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingQuest ? 'Edit Side Quest' : 'Tambah Side Quest'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Nama Side Quest"
            fullWidth
            margin="dense"
            value={newSideQuest.nama_side_quest}
            onChange={(e) =>
              setNewSideQuest({ ...newSideQuest, nama_side_quest: e.target.value })
            }
          />
          <TextField
            label="Harga"
            fullWidth
            margin="dense"
            type="number"
            value={newSideQuest.harga}
            onChange={(e) =>
              setNewSideQuest({ ...newSideQuest, harga: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Peta Tujuan</InputLabel>
            <Select
              value={newSideQuest.peta_id}
              onChange={handlePetaChange}
            >
              {petaList.map((peta) => (
                <MenuItem key={peta.id} value={peta.id}>
                  {peta.nama_peta}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedPeta && (
            <div
              className={classes.mapContainer}
              style={{ marginTop: 20 }}
              onClick={handleMapClick}
              ref={mapContainerRef}
            >
              <Typography variant="subtitle1" gutterBottom>
                Klik pada peta untuk menentukan lokasi quest
              </Typography>
              <ImageMapper
                src={`https://api.nusalarp.sagaracreativehouse.com/${selectedPeta.gambar_peta}`}
                map={map}
                width={600}
                imgWidth={600}
                onClick={() => {}} // Disable internal onClick
              />
              {currentLocation && (
                <>
                  <Typography variant="body2" style={{ marginTop: 10 }}>
                    Lokasi Geografis: Latitude: {currentLocation.lat}, Longitude: {currentLocation.lng}
                  </Typography>
                  {/* Flag Icon */}
                  <img
                    src="https://img.icons8.com/color/48/000000/marker.png" // Replace with your preferred flag icon URL
                    alt="Flag Icon"
                    className={classes.flagIcon}
                    style={{
                      top: `${currentLocation.lat}%`, // Inverted Y-axis
                      left: `${currentLocation.lng}%`,
                    }}
                  />
                </>
              )}
            </div>
          )}

          <TextField
            label="Lokasi Geografis (Lat, Lng)"
            fullWidth
            margin="dense"
            value={
              currentLocation
                ? `{"lat": ${currentLocation.lat}, "lng": ${currentLocation.lng}}`
                : ''
            }
            InputProps={{
              readOnly: true,
            }}
            style={{ marginTop: 20 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          {editingQuest ? (
            <Button onClick={handleUpdateSideQuest} color="primary">
              Update
            </Button>
          ) : (
            <Button onClick={handleAddSideQuestSubmit} color="primary">
              Tambah
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Side Quest Detail Dialog */}
      <SideQuestDetail
        open={detailOpen}
        handleClose={() => setDetailOpen(false)}
        questId={selectedQuestId}
      />
    </div>
  );
};

export default SideQuestManagement;
