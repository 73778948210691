// src/components/Common/Navbar.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, makeStyles } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { removeToken, removeUser } from '../../utils/auth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

const Navbar = () => {
  let { path, url } = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();

  const handleLogout = () => {
    removeToken();
    removeUser();
    history.push('/');
  };

  const handleProfil = () => {
    history.push(`${url}/profil`);
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          NusaLARP
        </Typography>
        <Button color="inherit" onClick={handleProfil}>
          Profil
        </Button>
        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
