// src/components/Dashboard/Player/PlayerDashboard.js
import React from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Fab,
  Menu,
  MenuItem,
} from '@material-ui/core';

import AssignmentIcon from '@material-ui/icons/Assignment';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import MuseumIcon from '@material-ui/icons/Museum';
import CropFreeIcon from '@material-ui/icons/CropFree'; // Icon for QR
import ListIcon from '@material-ui/icons/List';
import StoreIcon from '@material-ui/icons/Store';

import Profil from './Profil';
import ScanQRCode from './ScanQRCode';
import Rankings from './Rankings';
import CraftMarketPlayer from './CraftMarketPlayer';
import FoodMarketPlayer from './FoodMarketPlayer';
import TowerMuseumPlayer from './TowerMuseumPlayer';
import MainQuestList from './MainQuestList';
import SideQuestList from './SideQuestList';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 1, // Ensure the container is above other elements
  },
  bottomNav: {
    width: '100%',
    height: 60, // Adjust height as needed
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    position: 'absolute',
    top: 0, // Half of the FAB's height to overlap
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2, // Ensure the FAB is above the BottomNavigation
  },
  placeholder: {
    width: 56, // Width of the FAB to reserve space
    height: 56, // Height of the FAB to reserve space
  },
}));

const PlayerDashboard = () => {
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(null);
  const classes = useStyles();
  const history = useHistory(); // Use useHistory for navigation

  // State for Quests menu
  const [questsAnchorEl, setQuestsAnchorEl] = React.useState(null);
  const questsMenuOpen = Boolean(questsAnchorEl);

  // State for Market menu
  const [marketAnchorEl, setMarketAnchorEl] = React.useState(null);
  const marketMenuOpen = Boolean(marketAnchorEl);

  // Handle BottomNavigation changes
  const handleNavigationChange = (event, newValue) => {
    if (newValue === 'quests') {
      setQuestsAnchorEl(event.currentTarget);
    } else if (newValue === 'market') {
      setMarketAnchorEl(event.currentTarget);
    } else {
      setValue(newValue);
      switch (newValue) {
        case 'rankings':
          history.push(`${url}/rankings`);
          break;
        case 'museum':
          history.push(`${url}/tower-museum`);
          break;
        default:
          break;
      }
    }
  };

  // Handle closing of menus
  const handleMenuClose = () => {
    setQuestsAnchorEl(null);
    setMarketAnchorEl(null);
  };

  // Handle Quests menu item clicks
  const handleQuestsClick = (questType) => {
    handleMenuClose();
    setValue(null); // Reset BottomNavigation value
    if (questType === 'main') {
      history.push(`${url}/main-quest`);
    } else if (questType === 'side') {
      history.push(`${url}/side-quest`);
    }
  };

  // Handle Market menu item clicks
  const handleMarketClick = (marketType) => {
    handleMenuClose();
    setValue(null); // Reset BottomNavigation value
    if (marketType === 'craft') {
      history.push(`${url}/craft-market`);
    } else if (marketType === 'food') {
      history.push(`${url}/food-market`);
    }
  };

  // Handle FAB click
  const handleFabClick = () => {
    history.push(`${url}/scan-qrcode`);
  };

  return (
    <div>
      <Switch>
        <Route path={`${path}/main-quest`} component={MainQuestList} />
        <Route path={`${path}/side-quest`} component={SideQuestList} />
        <Route path={`${path}/profil`} component={Profil} />
        <Route path={`${path}/rankings`} component={Rankings} />
        <Route path={`${path}/craft-market`} component={CraftMarketPlayer} />
        <Route path={`${path}/scan-qrcode`} component={ScanQRCode} />
        <Route path={`${path}/food-market`} component={FoodMarketPlayer} />
        <Route path={`${path}/tower-museum`} component={TowerMuseumPlayer} />
        <Route exact path={path}>
          <ScanQRCode />
        </Route>
      </Switch>

      <div className={classes.container}>
        <BottomNavigation
          value={value}
          onChange={handleNavigationChange}
          showLabels
          className={classes.bottomNav}
        >
          <BottomNavigationAction
            label="Quests"
            icon={<ListIcon />}
            value="quests"
          />
          <BottomNavigationAction
            label="Rankings"
            icon={<EmojiPeopleIcon />}
            value="rankings"
          />
          <div className={classes.placeholder} /> {/* Placeholder for FAB */}
          <BottomNavigationAction
            label="Market"
            icon={<StoreIcon />}
            value="market"
          />
          <BottomNavigationAction
            label="Museum"
            icon={<MuseumIcon />}
            value="museum"
          />
        </BottomNavigation>

        {/* Floating Action Button for QR Scanning */}
        <Fab
          color="primary"
          aria-label="scan-qr"
          onClick={handleFabClick}
          className={classes.fab}
          variant="extended"
        >
          <CropFreeIcon />
        </Fab>
      </div>

      {/* Quests Dropdown Menu */}
      <Menu
        anchorEl={questsAnchorEl}
        open={questsMenuOpen}
        onClose={handleMenuClose}
        keepMounted
      >
        <MenuItem onClick={() => handleQuestsClick('main')}>Main Quest</MenuItem>
        <MenuItem onClick={() => handleQuestsClick('side')}>Side Quest</MenuItem>
      </Menu>

      {/* Market Dropdown Menu */}
      <Menu
        anchorEl={marketAnchorEl}
        open={marketMenuOpen}
        onClose={handleMenuClose}
        keepMounted
      >
        <MenuItem onClick={() => handleMarketClick('craft')}>Craft Market</MenuItem>
        <MenuItem onClick={() => handleMarketClick('food')}>Food Market</MenuItem>
      </Menu>
    </div>
  );
};

export default PlayerDashboard;
