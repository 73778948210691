import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  makeStyles,
  Container,
} from '@material-ui/core';
import api from '../../../services/api';
import RankingsDialog from './RankingsDialog';
import QRCodePopup from './QRCodePopup'; // Import QRCodePopup
import { getUser } from '../../../utils/auth';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MainQuestManagement = () => {
  const classes = useStyles();
  const user = getUser();
  const [mainQuests, setMainQuests] = useState([]);
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [rankingsOpen, setRankingsOpen] = useState(false);
  
  // State untuk QR Code Popup
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [selectedQrCode, setSelectedQrCode] = useState(null);

  useEffect(() => {
    fetchMainQuests();
  }, []);

  const fetchMainQuests = async () => {
    try {
      const response = await api.get('/quests/main');
      if (user.id) {
        const filteredQuests = response.data.filter(quest => quest.operator_id === user.id);
        setMainQuests(filteredQuests);
      }
    } catch (error) {
      console.error('Error fetching main quests:', error);
    }
  };

  const handleOpenRankings = (quest) => {
    setSelectedQuest(quest);
    setRankingsOpen(true);
  };

  const handleCloseRankings = () => {
    setSelectedQuest(null);
    setRankingsOpen(false);
  };

  // Fungsi untuk membuka QR Code Popup
  const handleOpenQrCode = (qrCode) => {
    setSelectedQrCode(qrCode);
    setQrCodeOpen(true);
  };

  const handleCloseQrCode = () => {
    setSelectedQrCode(null);
    setQrCodeOpen(false);
  };

  return (
    <Container className={classes.tableContainer}>
      <Typography variant="h5" gutterBottom>
        Main Quests yang Di-assign
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama Main Quest</TableCell>
            <TableCell>Phase</TableCell>
            <TableCell>QR Code</TableCell>
            <TableCell>Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mainQuests.map((quest) => (
            <TableRow key={quest.id}>
              <TableCell>{quest.nama_main_quest}</TableCell>
              <TableCell>{quest.phase}</TableCell>
              <TableCell>
                {quest.qr_code && (
                  <img
                    src={quest.qr_code}
                    alt="QR Code"
                    width="100"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOpenQrCode(quest.qr_code)}
                  />
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleOpenRankings(quest)}
                >
                  Rankings
                </Button>
                {/* Hapus tombol "Beri Poin" di sini */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog Rankings */}
      {selectedQuest && (
        <RankingsDialog
          open={rankingsOpen}
          handleClose={handleCloseRankings}
          quest={selectedQuest}
        />
      )}

      {/* QR Code Popup */}
      <QRCodePopup
        open={qrCodeOpen}
        handleClose={handleCloseQrCode}
        qrCode={selectedQrCode}
      />
    </Container>
  );
};

export default MainQuestManagement;