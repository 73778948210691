// src/components/Dashboard/Player/ScanQRCode.js
import React, { useState, useRef } from 'react';
import {
  Button,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Scanner } from '@yudiel/react-qr-scanner';
import api from '../../../services/api';
import { getUser } from '../../../utils/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  qrScanner: {
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
  },
  snackbar: {
    backgroundColor: (props) => {
      switch (props.severity) {
        case 'success':
          return theme.palette.success.main;
        case 'error':
          return theme.palette.error.main;
        case 'warning':
          return theme.palette.warning.main;
        case 'info':
          return theme.palette.info.main;
        default:
          return theme.palette.grey[700];
      }
    },
    color: theme.palette.common.white,
  },
}));

const ScanQRCode = () => {
  const classes = useStyles();
  const user = getUser();
  const scannerRef = useRef(null); // Reference to the Scanner component

  const [openDialog, setOpenDialog] = useState(false);
  const [scanResult, setScanResult] = useState('');
  const [questInfo, setQuestInfo] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success', 'error', 'warning', 'info'
  });

  const handleScan = async (data) => {
    if (data) {
      // Prevent multiple scans by stopping the scanner
      if (scannerRef.current) {
        scannerRef.current.stop();
      }

      const scannedData = data[0].rawValue;
      setScanResult(scannedData);
      setOpenDialog(true);

      // Parse data and handle quest entry
      // Example: MainQuest:QuestName:Phase
      const parts = scannedData.split(':');
      if (parts.length >= 3) {
        const questType = parts[0];
        const questName = parts[1];
        const phase = parts[2];
        // Fetch quest details from backend
        try {
          const endpoint =
            questType === 'MainQuest' ? `/quests/main/name/${questName}` : `/quests/side/name/${questName}`;
          const response = await api.get(endpoint);
          const quest = response.data;
          console.log(quest);
          setQuestInfo(quest);
        } catch (error) {
          console.error('Error fetching quest:', error);
          setSnackbar({
            open: true,
            message: 'Error fetching quest information',
            severity: 'error',
          });
        }
      } else {
        setSnackbar({
          open: true,
          message: 'QR Code tidak valid',
          severity: 'warning',
        });
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setSnackbar({
      open: true,
      message: 'Error scanning QR Code',
      severity: 'error',
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setScanResult('');
    setQuestInfo(null);
    // Restart the scanner after closing the dialog
    if (scannerRef.current) {
      scannerRef.current.start();
    }
  };

  const handleJoinQuest = async () => {
    try {
      const player_id = user.id; // Replace this with the actual player_id from your authentication/session data
      const fraksi_id = user.fraksi.id;
      const questType = scanResult.startsWith('MainQuest') ? 'Main' : 'Side';
      const quest_id = questInfo.id; // Assuming the questInfo contains the quest ID

      // Prepare the data to insert into the poin table
      const poinData = {
        tipe_quest: questType,
        quest_id: quest_id,
        player_id: player_id,
        fraksi_id: fraksi_id,
        poin: 0, // Initial points when joining the quest
        status: 'bermain', // Player starts in 'bermain' status
      };

      console.log(poinData);

      // Make an API call to insert the poin record
      await api.post('/quests/poin/join', poinData);

      setSnackbar({
        open: true,
        message: 'Anda telah bergabung ke quest',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error joining quest:', error.response?.data?.error || error.message);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Error joining quest',
        severity: 'error',
      });
    } finally {
      // Close the dialog regardless of success or error
      handleCloseDialog();
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Generate dynamic classes for Snackbar based on severity
  const snackbarClasses = useStyles(snackbar);

  return (
    <div className={classes.container}>
      <Typography variant="h5" gutterBottom>
        Scan QR Code untuk Bergabung ke Quest
      </Typography>
      <div className={classes.qrScanner}>
        <Scanner
          ref={scannerRef}
          onScan={handleScan}
          onError={handleError}
          allowMultiple={false} // Prevent multiple scans
          constraints={{ facingMode: 'environment' }}
          containerStyle={{ width: '100%' }}
        />
      </div>

      {/* Dialog Quest Information */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Informasi Quest</DialogTitle>
        <DialogContent>
          {questInfo ? (
            <div>
              <Typography variant="subtitle1">
                <strong>Nama Quest:</strong> {questInfo.nama_main_quest || questInfo.nama_side_quest}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Phase:</strong> {questInfo.phase || 'N/A'}
              </Typography>
              {/* Tambahkan informasi lainnya sesuai kebutuhan */}
            </div>
          ) : (
            <Typography variant="subtitle1">Tidak ada informasi quest tersedia.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          {questInfo && (
            <Button onClick={handleJoinQuest} color="primary">
              Bergabung
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        ContentProps={{
          className: classes.snackbar,
        }}
      />
    </div>
  );
};

export default ScanQRCode;
