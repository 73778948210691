// src/components/Dashboard/GameMaster/MainQuestPointManagement.js

import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Snackbar,
} from '@material-ui/core'; // Material-UI v4 core components
import { makeStyles } from '@material-ui/core/styles'; // makeStyles for styling
import api from '../../../services/api'; // API service for backend communication

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(8),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(10),
    },
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  formField: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: theme.spacing(2),
  },
}));

const MainQuestPointManagement = () => {
  const classes = useStyles();

  // State Variables
  const [mainQuests, setMainQuests] = useState([]);
  const [fraksiList, setFraksiList] = useState([]); // Fetching Fraksi instead of Players
  const [poinEntries, setPoinEntries] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newPoin, setNewPoin] = useState({
    main_quest_id: '',
    fraksi_id: '',
    poin: '',
  });
  const [editingPoin, setEditingPoin] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    fetchMainQuests();
    fetchFraksi();
    fetchPoinEntries();
  }, []);

  // Fetch Functions
  const fetchMainQuests = async () => {
    try {
      const response = await api.get('/quests/main');
      setMainQuests(response.data);
    } catch (error) {
      console.error('Error fetching main quests:', error);
      handleSnackbarOpen('Error fetching main quests');
    }
  };

  const fetchFraksi = async () => {
    try {
      const response = await api.get('/fraksi');
      setFraksiList(response.data);
    } catch (error) {
      console.error('Error fetching fraksi:', error);
      handleSnackbarOpen('Error fetching fraksi');
    }
  };

  const fetchPoinEntries = async () => {
    try {
      const response = await api.get('/quests/poin/main');
      setPoinEntries(response.data);
    } catch (error) {
      console.error('Error fetching poin entries:', error);
      handleSnackbarOpen('Error fetching poin entries');
    }
  };

  // Dialog Handlers
  const handleOpenAdd = () => {
    setEditingPoin(null);
    setNewPoin({
      main_quest_id: '',
      fraksi_id: '',
      poin: '',
    });
    setOpenDialog(true);
  };

  const handleOpenEdit = (poin) => {
    setEditingPoin(poin);
    setNewPoin({
      main_quest_id: poin.main_quest_id,
      fraksi_id: poin.fraksi_id,
      poin: poin.poin, // Allow editing the poin value
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingPoin(null);
    setNewPoin({
      main_quest_id: '',
      fraksi_id: '',
      poin: '',
    });
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // Form Change Handler
  const handleChange = (e) => {
    setNewPoin({
      ...newPoin,
      [e.target.name]: e.target.value,
    });
  };

  // Submit Handler
  const handleSubmit = async () => {
    const { main_quest_id, fraksi_id, poin } = newPoin;

    // Basic Validation
    if (!main_quest_id || !fraksi_id || poin === '') {
      handleSnackbarOpen('All fields are required');
      return;
    }

    if (isNaN(poin) || poin < 0) {
      handleSnackbarOpen('Poin must be a non-negative number');
      return;
    }

    try {
      if (editingPoin) {
        // Calculate the difference between new poin and existing poin
        const previousPoin = editingPoin.poin;
        const poinDifference = parseInt(poin, 10) - previousPoin;

        // Update the Poin entry with the new poin value
        await api.put(`/quests/poin/main/${editingPoin.id}`, {
          main_quest_id,
          fraksi_id,
          poin: parseInt(poin, 10),
        });

        // Adjust Fraksi's cumulative_poin based on the difference
        if (poinDifference !== 0) {
          if (poinDifference > 0) {
            await api.post(`/fraksi/${fraksi_id}/add_poin`, {
              poin: poinDifference,
            });
          } else {
            // Deduct the absolute value of poinDifference
            await api.post(`/fraksi/${fraksi_id}/deduct_poin`, {
              poin: Math.abs(poinDifference),
            });
          }
          handleSnackbarOpen('Poin entry and Fraksi cumulative poin updated successfully');
        } else {
          handleSnackbarOpen('Poin entry updated successfully');
        }
      } else {
        // Create a new Poin entry with the user-input poin value
        await api.post(`/quests/poin/main`, {
          main_quest_id,
          fraksi_id,
          poin: parseInt(poin, 10),
        });

        // Update Fraksi's cumulative_poin by adding the poin value
        await api.post(`/fraksi/${fraksi_id}/add_poin`, {
          poin: parseInt(poin, 10),
        });

        handleSnackbarOpen('Poin entry assigned and Fraksi cumulative poin updated successfully');
      }

      // Refresh the Poin entries list
      fetchPoinEntries();
      handleCloseDialog();
    } catch (error) {
      console.error('Error assigning/updating poin:', error);
      const errorMsg = error.response?.data?.error || 'Error assigning/updating poin';
      handleSnackbarOpen(errorMsg);
    }
  };

  // Delete Handler
  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this poin entry?')) return;

    try {
      // Get the poin entry to find the poin value and fraksi_id
      const poinEntry = poinEntries.find((entry) => entry.id === id);
      if (!poinEntry) {
        handleSnackbarOpen('Poin entry not found');
        return;
      }

      const { poin, fraksi_id } = poinEntry;

      // Delete the poin entry
      await api.delete(`/quests/poin/main/${id}`);

      // Deduct the poin from Fraksi's cumulative_poin
      await api.post(`/fraksi/${fraksi_id}/deduct_poin`, {
        poin: parseInt(poin, 10),
      });

      handleSnackbarOpen('Poin entry deleted and Fraksi cumulative poin updated successfully');
      fetchPoinEntries();
    } catch (error) {
      console.error('Error deleting poin entry:', error);
      handleSnackbarOpen('Poin entry deleted and Fraksi cumulative poin updated successfully');
    }
  };

  // Snackbar Handlers
  const handleSnackbarOpen = (message) => {
    setSnackbar({
      open: true,
      message,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // Create a mapping of fraksi_id to fraksi name for efficient lookup
  const fraksiMap = fraksiList.reduce((acc, fraksi) => {
    acc[fraksi.id] = fraksi.nama;
    return acc;
  }, {});

  return (
    <div className={classes.tableContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAdd}
        style={{ marginBottom: 16 }}
      >
        Assign Poin
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Main Quest</TableCell>
            <TableCell>Fraksi</TableCell>
            <TableCell>Poin</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {poinEntries.map((entry) => (
            <TableRow key={entry.id}>
              <TableCell>
                {entry.MainQuest && entry.MainQuest.nama_main_quest
                  ? entry.MainQuest.nama_main_quest
                  : '---'}
              </TableCell>
              <TableCell>
                {/* 
                  Option 1: If Fraksi data is included in the API response
                */}
                {entry.Fraksi && entry.Fraksi.nama ? entry.Fraksi.nama : '---'}

                {/* 
                  Option 2: If Fraksi data is NOT included in the API response,
                  map fraksi_id to fraksiList to find the Fraksi name
                */}
                {/* 
                {
                  (() => {
                    const fraksi = fraksiList.find((f) => f.id === entry.fraksi_id);
                    return fraksi ? fraksi.nama : '---';
                  })()
                }
                */}
              </TableCell>
              <TableCell>{entry.poin}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenEdit(entry)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(entry.id)}
                  style={{ marginLeft: 8 }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog for Assigning/Editing Poin */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{editingPoin ? 'Edit Poin' : 'Assign Poin'}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl className={classes.formControl}>
            <InputLabel>Main Quest</InputLabel>
            <Select
              name="main_quest_id"
              value={newPoin.main_quest_id}
              onChange={handleChange}
              disabled={editingPoin} // Disable Main Quest selection when editing to prevent changes
            >
              {mainQuests.map((quest) => (
                <MenuItem key={quest.id} value={quest.id}>
                  {quest.nama_main_quest}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Fraksi</InputLabel>
            <Select
              name="fraksi_id"
              value={newPoin.fraksi_id}
              onChange={handleChange}
              disabled={editingPoin} // Disable Fraksi selection when editing to prevent changes
            >
              {fraksiList.map((fraksi) => (
                <MenuItem key={fraksi.id} value={fraksi.id}>
                  {fraksi.nama}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Poin"
            name="poin"
            type="number"
            fullWidth
            className={classes.formField}
            value={newPoin.poin}
            onChange={handleChange}
            disabled={false} // Allow poin input both when adding and editing
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={
              !newPoin.main_quest_id || !newPoin.fraksi_id || newPoin.poin === ''
            }
          >
            {editingPoin ? 'Update' : 'Assign'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </div>
  );
};

export default MainQuestPointManagement;
