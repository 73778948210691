// src/components/Dashboard/GameMaster/GameMasterDashboard.js
import React, { useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'; // Updated import path for Material-UI v4
import { makeStyles } from '@material-ui/core/styles'; // makeStyles import for v4
import {
  People,
  Map,
  Assignment,
  ShoppingCart,
  Museum,
  Stars, // Icon for Quests
  MonetizationOn, // Replaced PointOfSale with MonetizationOn
} from '@material-ui/icons'; // Updated import path for Material-UI v4
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import UserManagement from './UserManagement';
import Rankings from './Rankings';
import PetaManagement from './PetaManagement';
import MainQuestManagement from './MainQuestManagement';
import SideQuestManagement from './SideQuestManagement';
import HiddenQuestManagement from './HiddenQuestManagement';
import CraftMarketManagement from './CraftMarketManagement';
import FoodMarketManagement from './FoodMarketManagement';
import TowerMuseumManagement from './TowerMuseumManagement';
import MainQuestPointManagement from './MainQuestPointManagement'; // Import the new component

const useStyles = makeStyles((theme) => ({
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 1000, // Ensure the navigation is above other elements
    boxShadow: '0 -2px 5px rgba(0,0,0,0.1)', // Optional: Add a subtle shadow
  },
}));

const GameMasterDashboard = () => {
  let { path, url } = useRouteMatch();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const classes = useStyles();

  // State for Quests Menu
  const [anchorElQuests, setAnchorElQuests] = useState(null);
  const openQuests = Boolean(anchorElQuests);

  // State for Give Point Menu
  const [anchorElGivePoint, setAnchorElGivePoint] = useState(null);
  const openGivePoint = Boolean(anchorElGivePoint);

  // State for Market Menu
  const [anchorElMarket, setAnchorElMarket] = useState(null);
  const openMarket = Boolean(anchorElMarket);

  const handleNavigation = (newValue) => {
    setValue(newValue);
    // No action here; navigation handled in menu items
  };

  const handleQuestsMenuClick = (event) => {
    setAnchorElQuests(event.currentTarget);
  };

  const handleQuestsMenuClose = () => {
    setAnchorElQuests(null);
  };

  const handleGivePointMenuClick = (event) => {
    setAnchorElGivePoint(event.currentTarget);
  };

  const handleGivePointMenuClose = () => {
    setAnchorElGivePoint(null);
  };

  const handleMarketMenuClick = (event) => {
    setAnchorElMarket(event.currentTarget);
  };

  const handleMarketMenuClose = () => {
    setAnchorElMarket(null);
  };

  const navigateTo = (path) => {
    history.push(path);
    handleQuestsMenuClose();
    handleGivePointMenuClose();
    handleMarketMenuClose();
  };

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/users`} />
        </Route>
        <Route path={`${path}/users`} component={UserManagement} />
        <Route path={`${path}/peta`} component={PetaManagement} />
        <Route path={`${path}/rankings`} component={Rankings} />
        <Route path={`${path}/quests`} component={MainQuestManagement} />
        <Route path={`${path}/side-quests`} component={SideQuestManagement} />
        <Route path={`${path}/hidden-quests`} component={HiddenQuestManagement} />
        <Route path={`${path}/main-quest-points`} component={MainQuestPointManagement} /> {/* New Route */}
        <Route path={`${path}/craft-market`} component={CraftMarketManagement} />
        <Route path={`${path}/food-market`} component={FoodMarketManagement} />
        <Route path={`${path}/tower-museum`} component={TowerMuseumManagement} />
      </Switch>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          handleNavigation(newValue);
        }}
        showLabels
        className={classes.bottomNav}
      >
        <BottomNavigationAction
          label="Users"
          icon={<People />}
          onClick={() => navigateTo(`${url}/users`)}
        />
        {/* <BottomNavigationAction
          label="Peta"
          icon={<Map />}
          onClick={() => navigateTo(`${url}/peta`)}
        /> */}
        <BottomNavigationAction
          label="Rankings"
          icon={<Map />}
          onClick={() => navigateTo(`${url}/rankings`)}
        />

        {/* Quests Dropdown Menu */}
        <BottomNavigationAction
          label="Quests"
          icon={<Assignment />}
          onClick={handleQuestsMenuClick}
        />
        <Menu
          anchorEl={anchorElQuests}
          open={openQuests}
          onClose={handleQuestsMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => navigateTo(`${url}/quests`)}>Main Quests</MenuItem>
          <MenuItem onClick={() => navigateTo(`${url}/side-quests`)}>Side Quests</MenuItem>
        </Menu>

        {/* Give Point Dropdown Menu */}
        <BottomNavigationAction
          label="Give Point"
          icon={<MonetizationOn />} // Replaced PointOfSale with MonetizationOn
          onClick={handleGivePointMenuClick}
        />
        <Menu
          anchorEl={anchorElGivePoint}
          open={openGivePoint}
          onClose={handleGivePointMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => navigateTo(`${url}/hidden-quests`)}>Hidden Quests Point</MenuItem>
          <MenuItem onClick={() => navigateTo(`${url}/main-quest-points`)}>Main Quest Point</MenuItem>
        </Menu>

        {/* Market Dropdown Menu */}
        <BottomNavigationAction
          label="Market"
          icon={<ShoppingCart />}
          onClick={handleMarketMenuClick}
        />
        <Menu
          anchorEl={anchorElMarket}
          open={openMarket}
          onClose={handleMarketMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => navigateTo(`${url}/craft-market`)}>Craft Market</MenuItem>
          <MenuItem onClick={() => navigateTo(`${url}/food-market`)}>Food Market</MenuItem>
          <MenuItem onClick={() => navigateTo(`${url}/tower-museum`)}>Tower Museum</MenuItem>
        </Menu>
      </BottomNavigation>
    </div>
  );
};

export default GameMasterDashboard;
