// src/pages/NotFound.js
import React from 'react';
import { Container, Typography } from '@material-ui/core';

const NotFound = () => {
  return (
    <Container>
      <Typography variant="h4" align="center" style={{ marginTop: '20vh' }}>
        404 - Halaman Tidak Ditemukan
      </Typography>
    </Container>
  );
};

export default NotFound;
