// src/components/Dashboard/Player/CraftMarketPlayer.js
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

const CraftMarketPlayer = () => {
  const classes = useStyles();
  const [tenants, setTenants] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);

  useEffect(() => {
    fetchCraftTenants();
  }, []);

  const fetchCraftTenants = async () => {
    try {
      const response = await api.get('/market/craft');
      setTenants(response.data);
    } catch (error) {
      console.error('Error fetching craft tenants:', error);
    }
  };

  const handleDetail = (tenant) => {
    setSelectedTenant(tenant);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTenant(null);
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5" gutterBottom>
        Craft Market
      </Typography>
      <Grid container spacing={4}>
        {tenants.map((tenant) => (
          <Grid item key={tenant.id} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              {tenant.foto_tenant && (
                <CardMedia
                  className={classes.media}
                  image={`https://api.nusalarp.sagaracreativehouse.com/${tenant.foto_tenant}`}
                  title={tenant.nama_tenant}
                />
              )}
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  {tenant.nama_tenant}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {tenant.deskripsi_tenant}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleDetail(tenant)}
                >
                  Detail
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog Detail Tenant */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Detail Tenant</DialogTitle>
        <DialogContent>
          {selectedTenant && (
            <div>
              <Typography variant="h6">{selectedTenant.nama_tenant}</Typography>
              <Typography variant="body1" gutterBottom>
                {selectedTenant.deskripsi_tenant}
              </Typography>
              {selectedTenant.foto_tenant && (
                <img
                  src={`https://api.nusalarp.sagaracreativehouse.com/${selectedTenant.foto_tenant}`}
                  alt={selectedTenant.nama_tenant}
                  width="100%"
                />
              )}
              {selectedTenant.logo_tenant && (
                <img
                  src={`https://api.nusalarp.sagaracreativehouse.com/${selectedTenant.logo_tenant}`}
                  alt={`${selectedTenant.nama_tenant} Logo`}
                  width="100px"
                />
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CraftMarketPlayer;
