// src/components/Layout/Layout.js
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Navbar from '../Common/Navbar';
import Footer from '../Common/Footer';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 90, // Height of BottomNavigation
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Navbar />
      <div className={classes.content}>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
