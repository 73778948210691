import React, { useEffect, useState } from 'react';
import { Typography, Box, List, ListItem, ListItemText, Divider, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, 
    makeStyles,
    Button, 
    Paper,
    Container} from '@material-ui/core';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}));

const SideQuestList = () => {
  const classes = useStyles();
  const [sideQuests, setSideQuests] = useState([]);
  const [mapImage, setMapImage] = useState('');
  const [openMapDialog, setOpenMapDialog] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetchSideQuests();
  }, []);

  const fetchSideQuests = async () => {
    try {
      // Dapatkan data side quests berdasarkan peta_id user
      const response = await api.get(`/quests/side/peta/${user.map_id}`);
      setSideQuests(response.data);

      // Dapatkan gambar peta
      if (response.data.length > 0) {
        const petaId = response.data[0].peta_id;
        const petaResponse = await api.get(`/peta/${petaId}`);
        setMapImage(petaResponse.data.gambar_peta);
      }
    } catch (error) {
      console.error('Fetch Side Quests Error:', error);
    }
  };

  const handleOpenMapDialog = () => {
    setOpenMapDialog(true);
  };

  const handleCloseMapDialog = () => {
    setOpenMapDialog(false);
  };

  return (
    <Container maxWidth="md">
      <Paper className={classes.container} elevation={3}>
            <Box sx={{ p: 2 }}>
            {mapImage && (
                <Box sx={{ mb: 2 }}>
                <Button variant="contained" color="primary" onClick={handleOpenMapDialog}>
                    Lihat Maps
                </Button>
                <Dialog
                    open={openMapDialog}
                    onClose={handleCloseMapDialog}
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle>Peta</DialogTitle>
                    <DialogContent>
                    <img
                        src={`https://api.nusalarp.sagaracreativehouse.com/${mapImage}`}
                        alt="Map"
                        style={{ width: '100%', height: 'auto' }}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseMapDialog} color="primary">
                        Tutup
                    </Button>
                    </DialogActions>
                </Dialog>
                </Box>
            )}
            <Typography variant="h5" gutterBottom>
                Side Quests
            </Typography>
            <List>
                {sideQuests.map((quest) => (
                <React.Fragment key={quest.id}>
                    <ListItem button>
                    <ListItemText
                        primary={quest.nama_side_quest}
                        secondary={`Point (Max): ${quest.harga}`}
                    />
                    </ListItem>
                    <Divider />
                </React.Fragment>
                ))}
            </List>
            </Box>
        </Paper>
    </Container>
  );
};

export default SideQuestList;
