// src/components/Dashboard/Operator/QRCodePopup.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  qrCodeImage: {
    width: '100%',
    height: 'auto',
    maxWidth: '400px', // Atur sesuai kebutuhan
    display: 'block',
    margin: '0 auto',
  },
}));

const QRCodePopup = ({ open, handleClose, qrCode }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>QR Code</DialogTitle>
      <DialogContent dividers>
        {qrCode ? (
          <img src={qrCode} alt="QR Code" className={classes.qrCodeImage} />
        ) : (
          <p>QR Code tidak tersedia.</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodePopup;
